@font-face {
  font-family: 'Source Sans Pro';
  src: url(./SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 200;
  src: url(./SourceSansPro-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 200;
  font-style: italic;
  src: url(./SourceSansPro-ExtraLightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 300;
  src: url(./SourceSansPro-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 300;
  font-style: italic;
  src: url(./SourceSansPro-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  src: url(./SourceSansPro-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-style: italic;
  src: url(./SourceSansPro-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  src: url(./SourceSansPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-style: italic;
  src: url(./SourceSansPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 800;
  src: url(./SourceSansPro-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 800;
  font-style: italic;
  src: url(./SourceSansPro-Black.ttf) format('truetype');
}
