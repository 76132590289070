$track-color: $primary;
$track-color-start: $score1;
$track-color-end: $primary;

$thumb-color: $secondary;

$thumb-radius: 50%;
$thumb-height: 25px;
$thumb-width: 25px;
$thumb-shadow-size: 0;
$thumb-shadow-blur: 0;
$thumb-shadow-color: transparent;
$thumb-border-width: 0;
$thumb-border-color: transparent;

$track-width: 100%;
$track-height: 6px;
$track-shadow-size: none;
$track-shadow-blur: none;
$track-shadow-color: transparent;
$track-border-width: 0;
$track-border-color: transparent;

$track-radius: 5px;
$contrast: 5%;

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color,
    0px 0px $shadow-size lighten($shadow-color, 5%);
}

@mixin track() {
  width: $track-width;
  height: $track-height;
  cursor: pointer;
  animate: 0.2s;
}

@mixin thumb() {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  border: $thumb-border-width solid $thumb-border-color;
  height: $thumb-height;
  width: $thumb-width;
  border-radius: $thumb-radius;
  background: $thumb-color;
  cursor: pointer;
}

input[type='range'] {
  -webkit-appearance: none;
  margin: $thumb-height/2 0;
  width: $track-width;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    @include track();
    @include shadow(
      $track-shadow-size,
      $track-shadow-blur,
      $track-shadow-color
    );
    //background: $track-color;
    background: linear-gradient(to right, $track-color-start, $track-color-end);
    border-radius: $track-radius;
    border: $track-border-width solid $track-border-color;
  }

  &::-webkit-slider-thumb {
    @include thumb();
    -webkit-appearance: none;
    margin-top: ((-$track-border-width * 2 + $track-height) / 2) -
      ($thumb-height / 2);
  }

  &:focus::-webkit-slider-runnable-track {
    background: lighten($track-color, $contrast);
  }

  &::-moz-range-track {
    @include track();
    @include shadow(
      $track-shadow-size,
      $track-shadow-blur,
      $track-shadow-color
    );
    //background: $track-color;
    background: linear-gradient(to right, $track-color-start, $track-color-end);
    border-radius: $track-radius;
    border: $track-border-width solid $track-border-color;
  }
  &::-moz-range-thumb {
    @include thumb();
  }

  &::-ms-track {
    @include track();
    background: transparent;
    border-color: transparent;
    border-width: $thumb-width 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: darken($track-color, $contrast);
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius * 2;
    @include shadow(
      $track-shadow-size,
      $track-shadow-blur,
      $track-shadow-color
    );
  }
  &::-ms-fill-upper {
    //background: $track-color;
    background: linear-gradient(to right, $track-color-start, $track-color-end);
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius * 2;
    @include shadow(
      $track-shadow-size,
      $track-shadow-blur,
      $track-shadow-color
    );
  }
  &::-ms-thumb {
    @include thumb();
  }
  &:focus::-ms-fill-lower {
    //background: $track-color;
    background: linear-gradient(to right, $track-color-start, $track-color-end);
  }
  &:focus::-ms-fill-upper {
    background: lighten($track-color, $contrast);
  }
}
