$font-family-sans-serif: 'Source Sans Pro';

$primary: #629abd;
$secondary: #074369;

$score1: #f2665e;
$score2: #629abd;
$score3: #005882;
$score4: #25b4b1;

$transSecondary: rgba(7, 67, 105, 0.8);
$h3-font-size: 32px;
$h4-font-size: 22px;
$h5-font-size: 16px;
$h6-font-size: 10px;

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
